import {
  getAppManifestFunc,
  openDashboard,
  openSettings,
  ACTIONS_ID,
} from './appManifest';
import { getTranslateFunction } from './i18n';

let sdk;
let _translate;

const editorReady = async (_editorSDK, _appToken, { initialAppData }) => {
  console.log('FAQ IN editorReady');
  sdk = _editorSDK;
  // _translate = await getTranslateFunction(initialAppData.languageCode || 'en');
  sdk.addEventListener('appActionClicked', (event) => {
    console.log('FAQ editorReady', { event });
  });
};

const getAppManifest = ({ appManifestBuilder }, _editorSDK) =>
  getAppManifestFunc({ appManifestBuilder }, _editorSDK, _translate);

module.exports = { editorReady, getAppManifest };
