export const ACTIONS_ID = {
  ADD_QUESTION_AND_ANSWER: 'Add Question & Answer',
  MANAGE_FAQS: 'Manage FAQs',
};

export const getAppManifestFunc = ({ appManifestBuilder }, editorSDK, t) => {
  return appManifestBuilder
    .configureManagementActions(async (managementActionsBuilder) => {
      managementActionsBuilder.mainActions().addAction({
        title: 'title1', // t('appmanifest.mainAction.manageFiles&Folders'),
        icon: 'file_share_folder_open',
        actionId: ACTIONS_ID.ADD_QUESTION_AND_ANSWER,
      });

      managementActionsBuilder.customActions().addAction({
        title: 'title2', // t('appmanifest.customAction.customizeDisplay'),
        icon: 'file_share_adjustments',
        type: 'editorActions',
        actionId: ACTIONS_ID.MANAGE_FAQS,
      });

      managementActionsBuilder.learnMoreAction().set({
        id: 'b7605423-9351-4d19-9299-892ff519e75b',
      });
    })
    .build();
};

// export const openDashboard = (editorSDK) => {
//   editorSDK.editor.openDashboardPanel('', {
//     url: 'app/14c92d28-031e-7910-c9a8-a670011e062d',
//     closeOtherPanels: false,
//   });
// };

// export const openSettings = async (editorSDK) => {
//   const allPages = await editorSDK.pages.data.getAll('');
// };
